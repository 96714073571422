

// get the zero count of project Measures
export const zeroCount = (state, getters, rootState) => (scenario, phase) => {

    let scenarioRooms = getters.getScenarioRoomsForPhase(scenario, phase)


    var zeroValuesCount = 0;
    var zeroMeasuresCount = 0;
    scenarioRooms.forEach( room => {
        if (!room.project_measures) {
            return;
        }
        room.project_measures.forEach(projectMeasure => {

            if (projectMeasure.measure.incentivized) {

                if (projectMeasure.kW_savings == null || projectMeasure.kW_savings == 0 || projectMeasure.kWh_savings == null || projectMeasure.kWh_savings == 0
                    || projectMeasure.full_cost == null || projectMeasure.full_cost == 0 || projectMeasure.measure_rebate == null || projectMeasure.measure_rebate == 0) {

                    zeroMeasuresCount++;

                    if (projectMeasure.kW_savings == null || projectMeasure.kW_savings == 0) {
                        zeroValuesCount++;
                    }

                    if (projectMeasure.kWh_savings == null || projectMeasure.kWh_savings == 0) {
                        zeroValuesCount++;
                    }

                    if (projectMeasure.full_cost == null || projectMeasure.full_cost == 0) {
                        zeroValuesCount++;
                    }

                    if (projectMeasure.measure_rebate == null || projectMeasure.measure_rebate == 0) {
                        zeroValuesCount++;
                    }
                }
            }
            else if (projectMeasure.full_cost == null || projectMeasure.full_cost == 0) {
                // Measures that are not incentivized only need to have Full Cost filled in
                zeroMeasuresCount++;
                zeroValuesCount++;
            }

        })
    });

    return {'zeroMeasuresCount': zeroMeasuresCount, 'zeroValuesCount': zeroValuesCount};
}

export const PMMeasureIds = state => (scenario, phase, roomId) => {

    let measureIds = [];

    state.projectMeasures.forEach(projectMeasure => {
        if(
            projectMeasure.scenario_id === scenario.id &&
            projectMeasure.phase_id === phase.id &&
            measureIds.indexOf(projectMeasure.measure_id) === -1
        ) {
            if(roomId && projectMeasure.room_id !== roomId) {
                return;
            }

            measureIds.push(projectMeasure.measure_id);
        }
    }
    );
    return measureIds;
}

export const PMEquipmentIds = state => (scenario, phase, roomId) => {

    let equipmentIds = [];

    state.projectMeasures.forEach(projectMeasure => {
        if(
            projectMeasure.scenario_id === scenario.id &&
            projectMeasure.phase_id === phase.id &&
            equipmentIds.indexOf(projectMeasure.equipment_id) === -1
        ) {
            if(roomId && projectMeasure.room_id !== roomId) {
                return;
            }

            if(projectMeasure.equipment_id) {
                equipmentIds.push(projectMeasure.equipment_id);
            }
        }
    }
    );
    return equipmentIds;
}


export const getScenarioRoomsForPhase = (state) => (scenario, phase) => {

    if(!state.scenarioRooms[scenario.id] || !state.scenarioRooms[scenario.id][phase.id]) {
        return [];
    }

    return state.scenarioRooms[scenario.id][phase.id];
}

export const getScenarioRoomsForPhaseAndRoomName = (state) => (scenario, phase, roomName) => {

    if(!state.scenarioRooms[scenario.id] || !state.scenarioRooms[scenario.id][phase.id]) {
        return null;
    }

    return state.scenarioRooms[scenario.id][phase.id].find(scenarioRoom => {
        return scenarioRoom.room_name === roomName;
    }) ?? null;

}

export const projectMeasuresToDelete = (state) => (mpid) => {
    return state.projectMeasuresToDelete[mpid];
}


export const getProjectMeasuresForScenarioPhaseAndRoom = state => (scenario, phase, room) => {

    return state.projectMeasures.filter(projectMeasure => {
        return projectMeasure.scenario_id === scenario.id &&
            projectMeasure.phase_id === phase.id &&
            projectMeasure.room_id === room.id;
    });

}

export const getTogglingSelectonForMeasure = state => () => {
    return state.togglingSelectionForMeasure;
}
