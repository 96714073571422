import Vue from 'vue';

export const defaultMPProjectMeasures = (state, mpid) => {
    Vue.set(state.projectMeasuresToDelete, mpid, []);
}


export const setScenarioRooms = (state, {rooms, scenario, phase}) => {

    if(!state.scenarioRooms[scenario.id]) {
        Vue.set(state.scenarioRooms, scenario.id, []);
    }

    Vue.set(state.scenarioRooms[scenario.id], phase.id, rooms)

}

export const upsertProjectMeasures = (state, {projectMeasures, projectId, scenario, phase, roomId}) => {

    if(!Array.isArray(projectMeasures)) {
        throw new Error("Project measures must ben an array");
    }

    // remove project measures from state
    state.projectMeasures = state.projectMeasures.filter(pm => {
        return pm.project_id !== projectId ||
            pm.scenario_id !== scenario.id ||
            pm.phase_id !== phase.id ||
            pm.room_id !== roomId;
    });

    projectMeasures.forEach(projectMeasure => {
        const existingProjectMeasureIndex = state.projectMeasures.findIndex(pm => {
            return pm.id === projectMeasure.id;
        })

        if(existingProjectMeasureIndex === -1) {
            state.projectMeasures.push(projectMeasure);
        } else {
            state.projectMeasures.splice(existingProjectMeasureIndex, 1, projectMeasure);
        }
    })
}

export const canUpdateCosts = (state, canUpdateCosts) => {
    state.canUpdateCosts = canUpdateCosts.canUpdate;
}

export const addRoom = (state, {room, scenario, phase}) => {
    state.scenarioRooms[scenario.id][phase.id].push(room);
}

export const updateScenarioRoom = (state, {scenarioRoom, scenario, phase}) => {

    state.scenarioRooms[scenario.id][phase.id].forEach((room, index) => {
        if(room.id == scenarioRoom.id) {
            state.scenarioRooms[scenario.id][phase.id].splice(index, 1, scenarioRoom);
        }
    })
}

export const deleteRoom = (state, {room, scenario, phase}) => {
    for(let i = 0; i < state.scenarioRooms[scenario.id][phase.id].length; i++){
       if (state.scenarioRooms[scenario.id][phase.id][i].id === room.id) {
         state.scenarioRooms[scenario.id][phase.id].splice(i, 1);
       }
    }
}

export const addProjectMeasureToRoom = (state, {projectMeasure, scenario, phase}) => {

    const existingPMIndex = state.projectMeasures.findIndex(pm => {
        return pm.id === projectMeasure.id;
    });

    if(existingPMIndex === -1) {
        state.projectMeasures.push(projectMeasure);
    } else {
        state.projectMeasures.splice(existingPMIndex, 1, projectMeasure);
    }

}

export const updateProjectMeasure = (state, {projectMeasure, scenario, phase}) => {

    const existingPMIndex = state.projectMeasures.findIndex(pm => {
        return pm.id === projectMeasure.id;
    });

    if(existingPMIndex === -1) {
        throw new Error("Unable to find project measure to update");
    }

    state.projectMeasures.splice(existingPMIndex, 1, projectMeasure);

}

export const removeProjectMeasures = (state, {projectMeasureIDs, scenario, phase}) => {

    projectMeasureIDs.forEach(projectMeasureID => {
        const existingPMIndex = state.projectMeasures.findIndex(pm => {
            return pm.id === projectMeasureID;
        });

        if(existingPMIndex !== -1) {
            state.projectMeasures.splice(existingPMIndex, 1);
        } else {
            throw new Error("Unable to find project measure to remove");
        }
    });
}


export const addProjectMeasureToDelete = (state, {projectMeasure, mpid}) => {
    state.projectMeasuresToDelete[mpid].push(projectMeasure)
}

export const removeProjectMeasureToDelete = (state, {projectMeasure, mpid}) => {

    let index = state.projectMeasuresToDelete[mpid].indexOf(projectMeasure);
    state.projectMeasuresToDelete[mpid].splice(index, 1);
}

export const resetProjectMeasuresToDelete = (state, mpid) => {
    state.projectMeasuresToDelete[mpid] = [];
}

export const setTogglingSelectionForMeasure = (state, projectMeasureId) => {
    state.togglingSelectionForMeasure = projectMeasureId;
}
